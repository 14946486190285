import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import ComingSoonView from 'src/sections/overview/app/comming-soon';
// import ComingSoonView from 'src/sections/coming-soon/view';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));


const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      // <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      //  </AuthGuard>
    ),
    children: [
      { element: <ComingSoonView />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      // {
      //   path: 'user',
      //   children: [
      //     { element: <ComingSoonView />, index: true },
      //     { path: 'profile', element: <ComingSoonView /> },
      //     { path: 'cards', element: <ComingSoonView /> },
      //     { path: 'list', element: <ComingSoonView /> },
      //     { path: 'new', element: <ComingSoonView /> },
      //     { path: ':id/edit', element: <ComingSoonView /> },
      //     { path: 'account', element: <ComingSoonView /> },
      //   ],
      // },
      {
        path: 'settings',
        children: [
          { element: <ComingSoonView />, index: true },
          { path: 'user-access', element: <ComingSoonView /> },
          { path: 'user-management', element: <ComingSoonView /> },
          { path: 'third-party-management', element: <ComingSoonView /> }
        ],
      },

      {
        path: 'report',
        children: [
          { element: <ComingSoonView />, index: true },
          { path: 'create', element: <ComingSoonView /> },
          { path: 'view', element: <ComingSoonView /> },
        ],
      },

      {
        path: 'data',
        children: [
          { element: <ComingSoonView />, index: true },
          { path: 'internal-data-source', element: <ComingSoonView /> },
          { path: 'external-data-source', element: <ComingSoonView /> },
          { path: 'view', element: <ComingSoonView /> },
        ],
      },

      // create dashboard
      { path: 'create', element: <ComingSoonView /> },
      // View dashboard
      { path: 'view', element: <ComingSoonView /> },
      // create report
      // {path: 'report/create', element: <ComingSoonView />},
      // // view report
      // {path: 'report/view', element: <ComingSoonView />},
      // //  internal Data source
      // {path: 'data/internal-data-source', element: <ComingSoonView />},
      // // external data source
      // {path: 'data/external-data-source', element: <ComingSoonView />},
      // // view/edit data
      // {path: 'data/view', element: <ComingSoonView />},

      { path: 'mail', element: <ComingSoonView /> },
      { path: 'chat', element: <ComingSoonView /> },
      { path: 'calendar', element: <ComingSoonView /> },
      { path: 'kanban', element: <ComingSoonView /> },
      { path: 'permission', element: <ComingSoonView /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];

import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
//
import NavList from './nav-list';
import Iconify from 'src/components/iconify';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function NavDesktop({ offsetTop, data }) {
  return (
    <Stack component="nav" direction="row" spacing={1} sx={{ mr: 2.5, height: 1, mt: 4 }}>
      {/* {data.map((link) => (
        <NavList key={link.title} item={link} offsetTop={offsetTop} />
      ))} */}

      {/*  user icon */}
      <>
      {/* <Typography variant="body2" mt={0.5} textAlign={'center'}>John Doe</Typography> */}
      {/* <Iconify icon="bi:person-circle" color="primary" width={30} height={30} /> */}
      </>
    </Stack>
  );
}

NavDesktop.propTypes = {
  data: PropTypes.array,
  offsetTop: PropTypes.bool,
};
